<template>
    <div class="app-container">
        <HeaderD/>
        <div class="app-content">
            <SidebarD/>
            <!-- <slot/> -->
            <div class="main">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
    import {HeaderD,SidebarD} from '@/components'
    export default {
        name:'Dashboard',
        components: {
            HeaderD,SidebarD
        }
    }
</script>

<style>
    @import "~@/assets/css/dashboard.css";
    .main{
        width: 100%;
        overflow: scroll;
    }
</style>